/**
 * ADD_NOTIFICATION action types
 */
export const ADD_NOTIFICATION_REQUEST = 'ADD_NOTIFICATION_REQUEST';
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS';
export const ADD_NOTIFICATION_ERROR = 'ADD_NOTIFICATION_ERROR';

/**
 * REMOVE_NOTIFICATION action types
 */
export const REMOVE_NOTIFICATION_REQUEST = 'REMOVE_NOTIFICATION_REQUEST';
export const REMOVE_NOTIFICATION_SUCCESS = 'REMOVE_NOTIFICATION_SUCCESS';
export const REMOVE_NOTIFICATION_ERROR = 'REMOVE_NOTIFICATION_ERROR';
